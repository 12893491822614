.main-container{
    margin: 0 3vw;
    display: flex;
}
.left{
    margin-right: 2rem;
    border-right: 1px solid;
    width: 50%;
    display: grid;
    gap: 1.5rem;
}
.left img{
    width: 2.5rem;
    height: 2.5rem;
}
.right{
    width: 50%;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    gap: 15rem;
}
.pages{
    display: grid;
    gap: 2rem;
}
.styl{
    font-size: 10px;
    color: grey;
}
.styl li{
    list-style: none;
    font-size: 10px;
}
.styl a{
    text-decoration: none;
    color: grey;
}
.footer-copyright{
    height: 2rem;
    margin: 3vw;
    border-top: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .left img{
        width: 1rem;
        height: 1rem;
    }
    .logo2{
        width: 2rem;
    }
    .badge-w{
        width: 1rem;
        height: 1rem;
    }
    .foot-desc{
        margin-right: 0rem;
        font-size: 0.6rem;
    }
    .right{
        width: 50%;
        margin-left: 2rem;
        margin-right: 2rem;
        display: flex;
        gap: 2rem;
    }
    .pages{
        display: grid;
        gap: 1rem;
    }
    .styl{
        font-size: 0.4rem;
        color: grey;
    }
    .fsze{
        font-size: 0.7rem;
    }
}