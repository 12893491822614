.motto-container{
    padding: 3vw;
    display: flex;
}
.motto{
    display: grid;
    width: 80%;
}
.motto1{
    font-size: 4.5rem;
    width: 35rem;
}
.motto2{
    width: 20%;
    display: flex;
    justify-content: space-between;
}
.motto3{
    width: 70%;
    font-size: 1.5rem;
    padding: 0 1rem 0px 0px;
}
.motto4{
    padding: 1.2rem 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    justify-content: end;
    width: 100%;
}
.motto4 button{
    width: 11rem;
    height: 2rem;
    font-size: 1rem;
    background: none;
    border: 1px solid;
    border-radius: 5px;
}
.box1 {
    grid-row: 1;
}

.box2 {
    grid-row: 3;
}
.motto4 button:hover{
    cursor: pointer;
}


@media only screen and (max-width: 1024px) {
    .motto1 {
        font-size: 2.5rem;
        width: 50%;
    }
    .motto3 {
        width: 80%;
        font-size: 1.5rem;
        padding: 0 1rem 0px 0px;
    }
}
@media only screen and (max-width: 820px) {
    .motto1 {
        font-size: 2.5rem;
        width: 30rem;
    }
    .motto3 {
        width: 80%;
        font-size: 1rem;
        padding: 0 1rem 0px 0px;
    }
}
@media only screen and (max-width: 768px) {
    .motto1 {
        font-size: 2.5rem;
        width: 20rem;
    }
    .motto3 {
        width: 80%;
        font-size: 1rem;
        padding: 0 1rem 0px 0px;
    }
}

@media only screen and (max-width: 600px) {
    .motto-container{
        padding: 3vw;
        display: flex;
    }
    .motto1 {
        font-size: 1.2rem;
        width: 11rem;
    }
    .motto3 {
        width: 80%;
        font-size: 0.6rem;
        padding: 0 1rem 0px 0px;
    }
    .motto4{
        padding: 0.4rem 0;
        display: grid;
        grid-template-rows: auto 1fr auto;
        justify-content: end;
        width: 100%;
    }
    .motto4 button {
        width: 5rem;
        height: 1rem;
        font-size: 6px;
        background: none;
        border: 1px solid;
        border-radius: 5px;
    }
}