.callback-main{
    display: flex;
    padding: 0 3vw;
}
.callback-left{
    display: grid;
    align-items: center;
    gap: 1rem;
    width: 40%;
    position: relative;
}
.callback-right{
    display: grid;
    gap: 1rem;
    width: 60%;
}
.fonsiz{
    position: absolute;
    top: 0;
}
.fonsiz1{
    font-size: 2rem;
}
.fonsiz2{
    font-size: 2.7rem;
}
.callback-right button{
    height: 2.2rem;
    width: 12rem;
    color: white;
    background-color: #ff7000;
    border: none;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .fonsiz1 {
        font-size: 1.7rem;
    }
    .fonsiz2 {
        font-size: 2.4rem;
    }
}
@media only screen and (max-width: 600px) {
    .fonsiz{
        font-size: 0.5rem;
    }
    .fonsiz1 {
        font-size: 1rem;
    }
    .fonsiz2 {
        font-size: 1.2rem;
    }
    .callback-right button{
        height: 1.5rem;
        width: 9rem;
        color: white;
        font-size: 9px;
        background-color: #ff7000;
        border: none;
        border-radius: 5px;
    }
}