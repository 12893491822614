.main-admin-cont{
    display: flex;
}
.left-admin-cont{
    width: 30%;
}
.right-admin-cont{
    width: 70%;
}
  
.main-admin-cont h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-top: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .main-admin-cont p {
    text-align: center;
    color: #666;
    font-size: 1.2rem;
    animation: fadeIn 1.2s ease-in-out;
  }
  
  .main-admin-cont {
    display: flex;
    max-width: 1200px;
    margin: 30px auto;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .left-admin-cont {
    background-color: #ff7000;
    padding: 20px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .left-admin-cont ul {
    list-style: none;
    padding: 0;
  }
  
  .left-admin-cont li {
    color: #fff;
    padding: 10px 0;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .left-admin-cont li:hover {
    background-color: #2c3e50;
    padding-left: 15px;
  }
  
  .right-admin-cont {
    flex-grow: 1;
    padding: 20px;
  }
  

  
  .add-jobs-active .show-add-jobs,
  .view-applications-active .show-view-applications {
    display: block;
    animation: slideUp 0.5s ease-out;
  }
  
  .main-admin-cont input, .main-admin-cont textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .main-admin-cont input:focus, .main-admin-cont textarea:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  }
  
  .main-admin-cont button, .other-jobs button {
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .main-admin-cont button:hover {
    transform: translateY(-2px);
  }
  
  .main-admin-cont button:active {
    transform: translateY(0);
  }
  
  .show-add-jobs button[type="submit"] {
    background-color: #28a745;
    color: #fff;
  }
  
  .show-add-jobs button[type="submit"]:hover {
    background-color: #218838;
  }
  
  .main-admin-cont button.logout {
    background-color: #e74c3c;
    color: #fff;
  }
  
  .main-admin-cont button.logout:hover {
    background-color: #c0392b;
  }
  
  .job-list {
    margin-top: 20px;
    border-top: 2px solid #ddd;
  }
  
  .job-list li {
    padding: 15px 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .job-list li:hover {
    background-color: #f9f9f9;
  }
  
  .job-list .buttonGroup button {
    margin-right: 10px;
    background-color: #3498db;
    color: #fff;
  }
  
  .job-list .buttonGroup button:hover {
    background-color: #2980b9;
  }
  
  .job-list .buttonGroup button.delete {
    background-color: #e74c3c;
  }
  
  .job-list .buttonGroup button.delete:hover {
    background-color: #c0392b;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .admsec{
    margin-top: 2vw;
  }
  /* .admsec p{
    height: 10vw !important;
  } */
  .show-view-applications {
    background-color: #ffffff; /* Card background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Inner spacing */
    margin: 20px auto; /* Centering the container */
    max-width: 800px; /* Maximum width for the card */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for interactivity */
  }
  
  .show-view-applications:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .applicationsHeading {
    font-size: 24px; /* Heading size */
    color: #333; /* Heading color */
    margin-bottom: 15px; /* Space below heading */
    text-align: center; /* Center the heading */
  }
  
  .applicationsList {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 2rem;
  }
  
  .applicationItem {
    border-bottom: 1px solid #e0e0e0; /* Separator between applications */
    padding: 15px 0; /* Vertical spacing for each item */
    background: #e7e4e4 !important;
    padding: 1vw !important;
    border-radius: 1vw;
  }
  .applicationItem:last-child {
    border-bottom: none; /* Remove separator for last item */
  }
  
  .application-actions {
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space out the buttons */
    margin-top: 10px; /* Space above buttons */
  }
  
  .hireButton,
  .rejectButton,
  .shortlistButton {
    padding: 10px 15px; /* Button padding */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners for buttons */
    cursor: pointer; /* Change cursor on hover */
    transition: background-color 0.3s ease; /* Animation for background color */
  }
  
  .hireButton {
    background-color: #28a745; /* Green for hire */
    color: white; /* White text */
  }
  
  .hireButton:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .rejectButton {
    background-color: #dc3545; /* Red for reject */
    color: white; /* White text */
  }
  
  .rejectButton:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  
  .shortlistButton {
    background-color: #ffc107; /* Yellow for shortlist */
    color: white; /* White text */
  }
  
  .shortlistButton:hover {
    background-color: #e0a800; /* Darker yellow on hover */
  }
  .applicationItem {
    border-bottom: 1px solid #e0e0e0; /* Separator between applications */
    padding: 15px 0; /* Vertical spacing for each item */
    transition: background-color 0.3s ease; /* Animation for background color */
  }
  .applicationItem p{
    text-align: start;
  }
  .applicationItem:hover {
    background-color: #f9f9f9; /* Light background on hover */
  }
  
  .workExperienceContainer {
    margin: 10px 0; /* Space around work experience */
    padding: 15px; /* Inner padding for the work experience box */
    border: 1px solid #e0e0e0; /* Border around work experience */
    border-radius: 5px; /* Rounded corners for work experience */
    background-color: #fafafa; /* Light background for work experience */
  }
  
  .workExperienceContainer h3 {
    margin: 0; /* Remove default margin */
    font-size: 18px; /* Font size for the job title */
    color: #333; /* Job title color */
  }
  
  .workExperienceDetails {
    margin: 5px 0; /* Space between details */
  }
  
  .workExperienceDetails strong {
    color: #555; /* Color for the labels */
  }
  