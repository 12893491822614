.start{
    margin-top: 3vw;
    padding: 0 3vw;
    display: flex;
    gap: 4rem;
}
.start1{
    width: 50%;
    display: grid;
    justify-content: center;
    align-items: center;
}
.start2{
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.box{
    height: 20rem;
    width: 90%;
    overflow: hidden;
    border-radius: 15px;
}
.box img{
    width: 100%;
    height: 100%;
    display: block;
}
.gap{
    display: grid;
    gap: 10px;
    width: 100%;
}
.gap .a{
    font-size: 1.5rem;
}
.gap .b{
    font-size: 4rem;
}
.gap button{
    background: #ff7000;
    height: 2.5rem;
    width: 18rem;
    border: 0px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
}

@media only screen and (max-width: 1024px) {
    .box{
        height: 100%;
        width: 90%;
        overflow: hidden;
        border-radius: 15px;
    }
    .box img{
        width: 100%;
        height: auto;
        display: block;
    }
    .gap .a{
        font-size: 1rem;
    }
    .gap .b{
        font-size: 3rem;
    }
    .gap button{
        background: #ff7000;
        height: 2.5rem;
        width: 10rem;
        border: 0px;
        border-radius: 5px;
        color: white;
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .box{
        height: 100%;
        width: 90%;
        overflow: hidden;
        border-radius: 15px;
    }
    .box img{
        width: 100%;
        height: auto;
        display: block;
    }
    .gap .a{
        font-size: 1rem;
    }
    .gap .b{
        font-size: 2.6rem;
    }
    .gap button{
        background: #ff7000;
        height: 2.5rem;
        width: 10rem;
        border: 0px;
        border-radius: 5px;
        color: white;
        font-size: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .start{
        margin-top: 3vw;
        padding: 0 3vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3vw;
    }
    .box{
        height: auto;
        width: auto;
        overflow: hidden;
        border-radius: 15px;
    }
    .box img{
        width: 100%;
        height: auto;
        display: block;
    }
    .gap .a{
        font-size: 0.5rem;
    }
    .gap .b{
        font-size: 1.3rem;
    }
    .gap button{
        background: #ff7000;
        height: 1.5rem;
        width: 10rem;
        border: 0px;
        border-radius: 5px;
        color: white;
        font-size: 15px;
    }
}