.industry-comp{
    padding: 0 3vw;
}
.industry-in-cont{
    display: flex;
    justify-content: space-between;
}
.dis-gri{
    padding: 0 3vw;
    justify-content: center;
    display: grid;
    gap: 1rem;
}
.dis-gri div{
    width: 8rem;
    display: flex;
    justify-content: center;
}
.fs13{
    font-size: 1.3rem;
}
.dis-gri img{
    height: 6rem;
}

@media only screen and (max-width: 820px) {
    .dis-gri img{
        height: 4rem;
    }
}
@media only screen and (max-width: 768px) {
    .dis-gri img{
        height: 3rem;
    }
    .dis-gri div{
        display: flex;
        justify-content: center;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .dis-gri img{
        height: 1.8rem;
    }
    .dis-gri div{
        display: flex;
        justify-content: center;
        font-size: 0.7rem;
    }
    .dis-gri div{
        width: 4rem;
        display: flex;
        justify-content: center;
    }
}