.about-main-container{
    display: flex;
    margin-top: 1.5rem;
    gap: 2rem;
}
.about-left{
    background-color: #f5decdcc;
    width: 40%;
    border-radius: 0.8rem;
}
.about-left img{
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 7rem;
    height: 65vh;
    width: 28vw;
}
.about-right{
    width: 60%;
}
.fon-title{
    font-size: 6rem;
}
.fon-desc{
    font-size: 1.5rem;
    margin-right: 5rem;
    color: grey;
}
.fon2{
    font-size: 1.2rem;
    margin-right: 8rem;
    color: grey;
}
.about-right ul{
    margin-top: 2rem;
    list-style: none;
    gap: 2.5rem;
    display: grid;
}
.about-right ul button{
    background: #ff7000;
    color: white;
    border: navajowhite;
    border-radius: 5px;
    height: 2rem;
    width: 11rem;
}

@media only screen and (max-width: 1100px) {
    .about-left img{
        margin-top: 4rem;
        margin-bottom: 4rem;
        margin-left: 5rem;
        height: 33vh;
        width: 28vw;
    }
}

@media only screen and (max-width: 820px) {
    .about-left img{
        margin-top: 4rem;
        margin-bottom: 4rem;
        margin-left: 3rem;
        height: 30vh;
        width: 30vw;
    }
    .about-right ul{
        margin-top: 2rem;
        list-style: none;
        gap: 1.5rem;
        display: grid;
    }
    .fon-title{
        font-size: 4rem;
    }
    .fon-desc{
        font-size: 1.3rem;
        margin-right: 3rem;
        color: grey;
    }
    .fon2{
        font-size: 1.1rem;
        margin-right: 4rem;
        color: grey;
    }
}

@media only screen and (max-width: 768px) {
    .about-left img{
        margin-top: 4rem;
        margin-bottom: 4rem;
        margin-left: 3rem;
        height: 30vh;
        width: 30vw;
    }
    .about-right ul{
        margin-top: 2rem;
        list-style: none;
        gap: 1.5rem;
        display: grid;
    }
    .fon-title{
        font-size: 4rem;
    }
    .fon-desc{
        font-size: 1.3rem;
        margin-right: 3rem;
        color: grey;
    }
    .fon2{
        font-size: 1.1rem;
        margin-right: 4rem;
        color: grey;
    }
}

@media only screen and (max-width: 600px) {
    .about-left img{
        margin-top: 4rem;
        margin-bottom: 4rem;
        margin-left: 1rem;
        height: 24vh;
        width: 30vw;
    }
    .about-right ul{
        margin-top: 2rem;
        list-style: none;
        gap: 0.8rem;
        display: grid;

    }
    .fon-title{
        font-size: 2rem;
    }
    .fon-desc{
        font-size: 0.7rem;
        margin-right: 2rem;
        color: grey;
    }
    .fon2{
        color: grey;
        font-size: 0.6rem;
        margin-right: 1rem;
    }
    .about-right ul button{
        background: #ff7000;
        color: white;
        font-size: 0.6rem;
        border: navajowhite;
        border-radius: 5px;
        height: 1.5rem;
        width: 6rem;
    }
}
