/* PrivacyPolicy.css */
.privacy-policy-container {
    padding: 3rem 4rem;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
 .privacy-policy-container h1, .privacy-policy-container h2 {
    color: #000;
    margin-bottom: 1rem;
  }
  .privacy-policy-container h1{
    color: #ff7000;
  }
  
  .privacy-policy-container ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
    margin-inline: 4rem;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 0.5rem;
  }
  
  .privacy-policy-container p {
    margin-bottom: 1.5rem;
    margin-inline: 2rem;
  }
  
  @media screen and (max-width: 768px) {
    .privacy-policy-container {
        padding: 1rem 2rem;
    }
    .privacy-policy-container p {
        margin-bottom: 1.5rem;
        margin-inline: 0rem;
      }
      .privacy-policy-container ul {
        margin: 1rem 0;
        padding-left: 1.5rem;
        margin-inline: 0rem;
      }
    .privacy-policy-container h1, .privacy-policy-container h2 {
      font-size: 1.5rem;
    }
  
    .privacy-policy-container p, .privacy-policy-container li {
      font-size: 1rem;
    }
  }
  