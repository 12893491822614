.dis-non{
    display: none;
}
.service-container{
    padding: 0 3vw;
    border-bottom: "1px solid";
}
.heading{
    font-size: 2rem;
    border-bottom: 1px solid;
}
.services-items{
    width: 50%;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.services-items img{
    width: 3rem;
}
.item{
    display: grid;
    gap: 0.5rem;
}
.items-cont{
    margin-left: 2rem;
}
.item-heading{
    font-size: 2rem;
}
.item-desc{
    width: 28rem;
    color: grey;
}
.item-button{
    height: 2rem;
    width: 28rem;
    background: none;
    border: 1px solid grey;
    color: #ff7000;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-button a{
    text-decoration: none;
    color: #ff7000;
}
.sec{
    display: flex;
    border-bottom: 1px solid grey;
}
.find-cont{
    font-size: 3.3rem;
    margin-right: 2rem;
}
.opt-cont{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}
.f-s{
    position: relative;
}
.apply-bt:hover{
    cursor: pointer;
}
.setpos{
    top: 180% !important;
    right: 18% !important;
}
.setposmob {
    top: 143% !important;
    right: 10% !important;
}
.border-right{
    border-right: 1px solid;
}

.seeker-cont{
    display: grid;
    gap: 1rem;
}
.colr{
    color: #000;
}
@media only screen and (max-width: 1024px) {
    .dis-non{
        display: none;
    }
    .item-heading{
        font-size: 1rem;
    }
    .item-desc{
        width: 22rem;
    }
    .item-button{
        height: 2rem;
        width: 22rem;
        background: none;
        border: 1px solid grey;
        color: #ff7000;
        font-size: 18px;
    }
    .find-cont{
        font-size: 2.5rem;
    }
    .opt-cont{
        display: grid;
        gap: 2rem;
    }
}

@media only screen and (max-width: 820px) {
    .dis-non{
        display: none;
    }
    .item-heading{
        font-size: 1rem;
    }
    .item-desc{
        width: 16rem;
    }
    .item-button{
        height: 2rem;
        width: 16rem;
        background: none;
        border: 1px solid grey;
        color: #ff7000;
        font-size: 18px;
    }
    .find-cont{
        font-size: 2rem;
    }
    .opt-cont{
        display: grid;
        gap: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .dis-non{
        display: none;
    }
    .item-heading{
        font-size: 1rem;
    }
    .item-desc{
        width: 16rem;
    }
    .item-button{
        height: 2rem;
        width: 16rem;
        background: none;
        border: 1px solid grey;
        color: #ff7000;
        font-size: 18px;
    }
    .find-cont{
        font-size: 2.2rem;
    }
    .opt-cont{
        display: grid;
        gap: 2rem;
    }
    .setposmob {
        top: 115% !important;
        right: 10% !important;
    }
}
@media only screen and (max-width: 600px) {
    .dis{
        display: none;
    }
    .dis-non{
        display: block;
    }
    .services-items img{
        width: 1.5rem;
    }
    .heading{
        font-size: 1.2rem;
        border-bottom: 1px solid;
    }
    .item-heading{
        font-size: 0.7rem;
    }
    .item-desc{
        width: 7rem;
        font-size: 0.5rem;
    }
    .item-button{
        height: 1rem;
        width: 7rem;
        background: none;
        border: 1px solid grey;
        color: #ff7000;
        font-size: 11px;
    }
    .f-s{
        font-size: 0.6rem;
    }


    .seeker-cont{
        display: grid;
        gap: 1rem;
    }
    .f-size{
        font-size: 1.5rem;
    }
    .w-c{
        width: 8rem;
    }
    .opt-cont{
        display: flex;
        gap: 2rem;
    }
}