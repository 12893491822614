.out-cont{
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin: 3vw;
}
.in-cont{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.part1{
    width: 50%;
    width: 50%;
    display: grid;
    justify-content: center;
    align-items: center;

}
.part1 ul{
    display: grid;
    gap: 2rem;
    list-style: none;
    font-size: 1.2rem;
    color: grey;
}
.part2{
    width: 50%;
    height: auto;
    overflow: hidden;
}
.part2 img{
    height: 100%;
    width: 100%;
}
.sze{
    font-size: 5rem;
    color: black;
}
.d-sz{
    font-size: 1.4rem;
}
@media only screen and (max-width: 1100px) {
    .sze{
        font-size: 4rem;
        color: black;
    }
    .d-sz{
        font-size: 1rem;
    }
}

@media only screen and (max-width: 820px) {
    .part1 ul{
        display: grid;
        gap: 2rem;
        list-style: none;
        font-size: 1.1rem;
        color: grey;
    }
    .sze{
        font-size: 3rem;
        color: black;
    }
}
@media only screen and (max-width: 768px) {
    .part1 ul{
        display: grid;
        gap: 2rem;
        list-style: none;
        font-size: 1.1rem;
        color: grey;
    }
    .sze{
        font-size: 2rem;
        color: black;
    }
}

@media only screen and (max-width: 600px) {
    .part1 ul{
        display: grid;
        gap: 1rem;
        list-style: none;
        font-size: 1.1rem;
        color: grey;
    }
    .sze{
        font-size: 1rem;
        color: black;
    }
    .d-sz{
        font-size: 0.5rem;
    }
}