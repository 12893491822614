.requirements p{
    text-align: start;
    color: #000;
    font-size: 1rem;
}
.other-jobs .job-card{
    display: flex !important;
    flex-direction: column;
}
.other-jobs .job-card p{
    text-align: start;
}
.other-jobs .job-card ul{
    margin-top: 0.5vw;
}
.other-jobs h3{
    text-align: center !important;
}