.apply-form {
    max-width: 80%;
    margin: 50px auto;
    padding: 30px 5vw;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.apply-form p{
    width: 100%;
}
.apply-form-d{
    display: flex;
    gap: 1vw;
}
.apply-form-d input{
    width: 100%;
}
h1 {
    text-align: start;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.apply-form input, .apply-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.apply-form input:focus, .apply-form textarea:focus {
    border-color: #007bff;
    outline: none;
}

.submit-btn {
    display: flex;
    width: 20%;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #ff7000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.submit-btn:hover {
    background-color: #ed7315;
    transform: scale(1.05);
}

.popup-message {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.work-experience h2{
    margin: 2vw 0;
}
.work-experience button{
    background: transparent;
    border: 1px solid grey;
    padding: 0.5vw 1vw;
    border-radius: 3rem;
    margin-bottom: 1vw;
}
.upl-res{
    padding: 0.5vw 0;
    color: #5f6368;
}
.applicant-status{
    display: flex;
    justify-content: end;
    margin-right: 3vw;
}
@media screen and (max-width: 768px){
    .apply-form {
        max-width: 90%;
        margin: 25px auto;
        padding: 30px 5vw;
    }
    .submit-btn {
        width: auto;
    }
    .apply-form input, .apply-form textarea {
        width: 100%;
    }
}