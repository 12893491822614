.visit-main{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 2rem;
}
.v1{
    gap: 1rem;
    border-right: 1px solid;
}
.v1_in{
    display: grid;
    margin-top: 2rem;
}
.v2{
    display: grid;
    gap: 2rem;
    justify-content: space-between;
}
.v2 img{
    height: 58vh;
    width: 36vw;
}
.img-siz{
    width: 5rem;
}
.img-siz img{
    width: 100%;
}
.title-siz{
    font-size: 1.7rem;
    margin-top: 1rem;
}
.desc-siz{
    margin-right: 3rem;
    margin-top: 1rem;
    font-size: 1.3rem;
    color: grey;
}


@media only screen and (max-width: 1024px) {
    .img-siz img{
        width: 3rem;
    }
    .title-siz{
        font-size: 1.5rem;
        margin-top: 1rem;
    }
    .desc-siz{
        margin-right: 3rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        color: grey;
    }
    .v2 img{
        height: 25vh;
        width: auto;
    }
}

@media only screen and (max-width: 600px) {
    .visit-main{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .v1{
        width: 50%;
        border-right: 1px solid;
    }
    .v1_in{
        display: grid;
        margin-top: 1rem;
    }
    .v2{
        width: 50%;
        display: grid;
        height: 100%;
        gap: 2rem;
        justify-content: space-between;
    }
    .img-siz img{
        width: 2rem;
    }
    .title-siz{
        font-size: 0.8rem;
        margin-top: 0.3rem;
    }
    .desc-siz{
        height: 2rem;
        font-size: 0.4rem;
        margin-bottom: 0.3rem;
        margin-right: 0.3rem;
        margin-top: 0.3rem;
        color: grey;
    }
    .v2 img{
        height: auto;
        width: 10rem;
    }
}