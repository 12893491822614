.connect-main{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 2rem;
}
.lef{
    border-right: 1px solid;
    display: grid;
    gap: 1rem;
}
.fonzi{
    font-size: 6rem;
}
.fonzi2{
    font-size: 1.7rem;
    margin-right: 2rem;
    color: grey;
}
.w-i{
    width: 22rem;
}

@media only screen and (max-width: 1024px) {
    .lef {
        border-right: 1px solid;
        display: grid;
        gap: 0rem;
    }
    .fonzi {
        font-size: 4rem;
    }
    .fonzi2{
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 820px) {
    .lef {
        border-right: 1px solid;
        display: grid;
        gap: 0rem;
    }
    .fonzi {
        font-size: 3rem;
    }
    .fonzi2{
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 786px) {
    .lef {
        border-right: 1px solid;
        display: grid;
        gap: 0rem;
    }
    .fonzi {
        font-size: 2.6rem;
    }
    .fonzi2{
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 600px) {
    .lef {
        border-right: 1px solid;
        display: grid;
        gap: 0rem;
    }
    .fonzi {
        font-size: 1.2rem;
    }
    .fonzi2{
        font-size: 0.6rem;
    }
    .w-i{
        width: 9rem;
    }
}