/* career page */

.career-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0 4rem;
    margin-top: 2rem;
  }
  .career-container input, .career-container textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.career-container input:focus, .career-container textarea:focus {
    border-color: #007bff;
    outline: none;
}
  .filter-sidebar {
    width: 25%;
    padding: 20px;
    border-right: 1px solid #ccc;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    margin-right: 20px;
    height: fit-content;
    position: sticky;
    top: 20px;
  }
  
  .main-content {
    flex-grow: 1;
  }
  
  .filter-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s;
  }
  
  .filter-option:hover {
    background-color: #f1f3f4;
  }
  
  .job-count {
    font-size: 24px;
    font-weight: 500;
    /* margin-bottom: 20px; */
    color: #202124;
  }
  
  .share-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    padding: .3rem 1rem;
    border-radius: 10px;
  }
  
  
  .search-bar {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #dadce0;
    border-radius: 24px;
    font-size: 16px;
    transition: box-shadow 0.3s;
  }
  
  .search-bar:focus {
    outline: none;
    box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
  }
  
  .job-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    transition: box-shadow 0.3s;
  }
  
  .job-card:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  }
  
  .j-title {
    color: #C4C800;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .job-details {
    color: #5f6368;
    font-size: 14px;
    margin-block: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
  }
  
  .job-details img{
    width: 20px;
    height: 20px;
  }
  
  .job-details div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
  }
  
  
  .job-qualifications {
    margin-top: 15px;
  }
  
  .job-qualifications ul {
    margin-top: .6rem;
  }
  
  .job-qualifications li {
    color: #5f6368;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }
  
  .action-button {
    background: none;
    border: none;
    /* color: #C4C800; */
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .action-button:hover {
    background-color: rgba(26, 115, 232, 0.1);
  }
  
  .learn-more {
    /* color: #C4C800; */
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #1a73e8;
    color:#1a73e8 ;
    padding: .5rem 1rem;
    border-radius: 10px;
  }
  
  .learn-more:hover {
    text-decoration: underline;
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
  }
  
  .clear-filters,
  .job-alerts {
    background: none;
    border: none;
    /* color: #C4C800; */
    cursor: pointer;
    font-size: 14px;
  }
  
  .clear-filters{
    margin-top: 5px;
    color: red;
    border: 1px solid red;
    padding: .5rem 1rem;
    border-radius: 10px;
  }
  
  .job-alerts {
    display: flex;
    align-items: center;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .career-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .career-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked+.career-slider {
    background-color: #C4C800;
  }
  
  input:checked+.career-slider:before {
    transform: translateX(20px);
  }
  
  .filters-container {
    display: none;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 10px;
    }
  
  
    .filters-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
    }
  
    .filter-dropdown {
      position: relative;
      display: inline-block;
    }
  
    .filter-button {
      background-color: #fff;
      border: 1px solid #dadce0;
      border-radius: 18px;
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  
    .filter-button:hover {
      background-color: #f1f3f4;
    }
  
    .filter-content {
      position: absolute;
      background-color: #fff;
      min-width: 160px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      z-index: 1;
      border-radius: 4px;
      padding: 8px 0;
    }
  
    .filter-content a {
      color: #202124;
      padding: 8px 16px;
      text-decoration: none;
      display: block;
      font-size: 14px;
    }
  
    .filter-content a:hover {
      background-color: #f1f3f4;
    }
  
    .show {
      display: block;
    }
  
    .job-count {
      font-size: 20px;
    }
  
    .top-bar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .job-alerts {
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width : 600px) {
    .main-content {
      margin-bottom: 5rem;
    }
  }
  


  .job-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 5rem;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
    margin-top: 3rem;
    position: relative;
  }
  
  
  
  .job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dadce0;
    background-color: #fff;
    position: absolute;
    width: 96%;
    top: 0px;
  
  }
  
  .job-logo {
    height: 30px;
  }
  
  .back-button {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .back-arrow {
    margin-right: 5px;
  }
  
  .job-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  
  }
  
  .job-meta {
    display: flex;
    gap: 2rem;
    align-items: center;
    color: #5f6368;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .job-meta p {
    display: flex;
    font-size: 1rem;
}
  .job-meta img {
    height: 16px;
    margin-right: 5px;
  }
  
  .apply-share {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .apply-btn {
    background-color: #ff7000;
    color: #fff;
    font-weight: bold;
    border: none;
    padding: .6rem 2rem;
    border-radius: 10px;
  }
  
  .bottom-cont {
    justify-content: space-between;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .second-title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #202124;
  }
  
  .requirements {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .4rem;
    flex-direction: column;
    padding: 1rem;
  }
  .jobs-listing{
    width: 70%;
  }
  .job-footer {
    background-color: #f8f9fa;
    padding: 20px;
    font-size: 12px;
    color: #5f6368;
    border-top: 1px solid #dadce0;
  }
  
  .other-jobs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
  
  .job-card {
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 1rem 2rem;
    transition: box-shadow 0.3s;
  }
  
  .job-card:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  }
  
  .job-card h3 {
    text-align: start;
    font-size: 16px;
    margin-bottom: 8px;
    color: #000;
}
  
  .job-card p {
    font-size: 14px;
    color: #5f6368;
    margin-bottom: 4px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .filter-dropdown {
    margin-bottom: 15px;
  }
  
  .filter-option {
    margin-top: 5px;
    cursor: pointer;
    padding: 10px;
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  .filter-content {
    margin-top: 5px;
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .filter-content a {
    display: block;
    padding: 5px;
    text-decoration: none;
    color: #333;
  }
  
  .filter-content a:hover {
    background: #f1f1f1;
  }
  
  .job-listings {
    flex-grow: 1;
    width: 75%;
  }
  .job-listings input{
    width: 100%;
    border-radius: 3rem;
  }
  .job-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem 3rem;
    margin-bottom: 15px;
}
    
.share-icon {
 
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: .3rem 1rem;
  border-radius: 10px;
  position: relative;
}

.share-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: none;
  flex-direction: column;
  min-width: 150px;
}

.share-option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.share-option:hover {
  background-color: #f0f0f0;
}

.share-option img {
  width: 20px;
  height: 20px;
}
.active-filters{
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  padding: 1vw;
}
.filter-tag{
  background: #e9dfdf;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
}
.close-btn{
  margin-left: 1vw;
  font-size: 1.5vw;
}
.close-btn:hover{
  cursor: pointer;
}
@media screen and (max-width: 768px){
  .career-container {
    flex-direction: column;
    padding: 20px;
  }
  .filter-sidebar{
    width: 100%;
    position: static;
    margin-bottom: 2rem;
  }
  .filter-option{
    width: 100%;
  }
  .job-listings {
    width: 100%;
}
.job-card {
  padding: 1rem 1.5rem;
}
.job-details {
  font-size: 12px;
  gap: 1.5rem;
}
.job-container {
  margin-top: 0rem;
}
.job-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
}