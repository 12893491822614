.outer-container{
    display: grid;
    padding: 0 3vw;
}
.inner-container{
    display: grid;
}
.fle{
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.fle button{
    background: #ff7000;
    color: white;
    height: 2rem;
    width: 10rem;
    border: none;
    border-radius: 5px;
}
.fle input{
    border: none;
    outline: none;
    font-size: 1rem;
    width: 20rem;
    border-bottom: 1px solid;
    height: 2rem;
}
.sz{
    width: 39rem;
    font-size: 4rem;
}

@media only screen and (max-width: 1024px) {
    .fle input{
        border: none;
        outline: none;
        font-size: 1rem;
        width: 21rem;
        border-bottom: 1px solid;
        height: 2rem;
    }
    .sz{
        width: 26rem;
        font-size: 2rem;
    }
}

@media only screen and (max-width: 820px) {
    .fle input{
        border: none;
        outline: none;
        font-size: 1rem;
        width: 16rem;
        border-bottom: 1px solid;
        height: 2rem;
    }
    .sz{
        width: 20rem;
        font-size: 1.7rem;
    }
}

@media only screen and (max-width: 768px) {
    .fle input{
        border: none;
        outline: none;
        font-size: 1rem;
        width: 16rem;
        border-bottom: 1px solid;
        height: 2rem;
    }
    .sz{
        width: 18rem;
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .fle input{
        border: none;
        outline: none;
        font-size: 0.6rem;
        width: 7rem;
        border-bottom: 1px solid;
        height: 2rem;
    }
    .sz{
        width: 8rem;
        font-size: 0.8rem;
    }
    .fs{
        font-size: 0.4rem;
    }
    .fle button{
        background: #ff7000;
        color: white;
        height: 1.5rem;
        font-size: 0.5rem;
        width: 5rem;
        border: none;
        border-radius: 5px;
    }
    .inner-container{
        display: grid;
        gap: 0.5rem;
    }
}