.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    z-index: 1;
    width: 100%;
    padding: 0 3vw;
    border-bottom: 1.5px solid grey;
}
.nav1{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.logo{
    width: 8rem;
}
.nav2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}
.nav2 li{
    all: unset;
}
.nav2 li a{
    text-decoration: none;
}
.contact button{
    height: 2.2rem;
    width: 10rem;
    background: #ff7000;
    color: white;
    border: 0px;
    border-radius: 4px;
}
.contact button:hover{
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        z-index: 1;
        width: 100%;
        padding: 0 3vw;
        border-bottom: 1.5px solid grey;
    }
    .logo{
        width: 4rem;
    }
    .nav2{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        font-size: 0.5rem;
        color: black;
    }
    .contact button{
        height: 1.2rem;
        width: 5rem;
        font-size: 7px;
        background: #ff7000;
        color: white;
        border: 0px;
        border-radius: 4px;
    }
}