.heading2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin: 2rem;
}
.contai{
    display: flex;
    padding: 0 3vw;
}
.contai-left{
    width: 50%;
    display: flex;
    align-items: center;
}
.contai-right{
    width: 50%;
    display: grid;
}
.contai-right ul{
    list-style: none;
    display: grid;
    gap: 1rem;
}
.contai-right-1{
    display: flex;
    gap: 2rem;
}
.contai-right-2{
    display: flex;
    gap: 2rem;
}
.siz1{
    font-size: 2rem;
}
.siz2{
    font-size: 1.4rem;
    color: grey;
}


.mgn-lin-1{
    width: 0;
    border-left: 1px solid;
    margin-left: 2rem;
    margin-right: 2rem;
}

.mgn-lin-2{
    height: 0;
    border-bottom: 1px solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
}



.container{
    border-top: 1px solid;
    margin: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid;
}
.cont-size{
    width: 50%;
}
.box1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25rem;
    width: 50%;
    border-right: 1px solid grey;
}
.img2{
    height: 80vh;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.img2 img{
    width: 100%;
    height: 100%;
    display: block;
}
.box2{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 50%;
}
.request-button{
    background-color: #ff7000;
    width: 21rem;
    border: 0px;
    height: 2rem;
    color: white;
    border-radius: 5px;
}
.badge-sze{
    width: 2rem;
}

@media only screen and (max-width: 1024px) {
    .img2{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .img2 img{
        width: 100%;
        height: 100%;
        display: block;
    }
    .siz1 {
        font-size: 1.2rem;
    }
    .siz2{
        font-size: 1.2rem;
        color: grey;
    }
}

@media only screen and (max-width: 820px) {
    .img2{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .img2 img{
        width: 100%;
        height: 100%;
        display: block;
    }
    .siz2{
        font-size: 0.8rem;
        color: grey;
    }
}

@media only screen and (max-width: 768px) {
    .img2{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .img2 img{
        width: 100%;
        height: 100%;
        display: block;
    }
    .siz2{
        font-size: 0.8rem;
        color: grey;
    }
}
@media only screen and (max-width: 600px) {
    .heading2{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        margin: 1vw;
    }
    .img2{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .img2 img{
        width: 100%;
        height: 100%;
        display: block;
    }
    .siz1{
        font-size: 0.8rem;
    }
    .siz2{
        font-size: 0.4rem;
        color: grey;
    }
    .badge-sze{
        width: 1rem;
    }
    .contai-left{
        width: 50%;
    }
    .contai-right{
        width: 50%;
    }
    .contai-right ul {
        list-style: none;
        display: grid;
        gap: 0.2rem;
    }
    .contai-right-1{
        display: flex;
        gap: 1rem;
    }
    .contai-right-2{
        display: flex;
        gap: 1rem;
    }
    .mgn-lin-1{
        width: 0;
        border-left: 1px solid;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .mgn-lin-2{
        height: 0;
        border-bottom: 1px solid;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}