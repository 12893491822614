.out{
    display: flex;
}
.l1{
    width: 30%;
    display: grid;
    gap: 2rem;
}
.l1 ul{
    list-style: none;
}
.l2{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gre{
    color: grey;
}
.marsiz{
    margin-right: 5rem;
    font-size: 1.2rem;
    color: grey;
}
.fz{
    font-size: 3rem;
}

@media only screen and (max-width: 1024px) {
    .marsiz{
        margin-right: 10rem;
        font-size: 1.2rem;
        color: grey;
    }
}

@media only screen and (max-width: 768px) {
    .marsiz{
        margin-right: 5rem;
        font-size: 1.2rem;
        color: grey;
    }
    .fz{
        font-size: 2.6rem;
    }
}

@media only screen and (max-width: 600px) {
    .l1{
        width: 30%;
        display: grid;
        gap: 1rem;
    }
    .marsiz{
        margin-right: 1rem;
        font-size: 0.5rem;
        color: grey;
    }
    .fz{
        font-size: 1.1rem;
    }
    .gre{
        color: grey;
        font-size: 0.5rem;
    }
    .h-d{
        font-size: 0.5rem;
    }
}