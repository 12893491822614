.asd{
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
}
.serv-cont{
    padding: 3vw;
}
.serv-inner-cont{
    display: flex;
    justify-content: space-between;
}
.hiring-card{
    width: 24rem;
}
.hed-siz{
    font-size: 1.8rem;
    margin-bottom: 1rem;
}
.desc-siz{
    height: 6rem;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: grey;
}
.hiring-card button{
    width: 100%;
    height: 2rem;
    color: #ff7000;
    border: 1px solid black;
    border-radius: 5px;
    background: white;
}
.hiring-card button:hover{
    cursor: pointer;
}




.stu-comp{
    padding: 0 3vw;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.stu-l{
    width: 60%;
    display: grid;
    justify-content: space-between;
    gap: 2rem;
}
.stu-l .hiring-card{
    width: 38rem;
}
.stu-l .desc-size{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: grey;
}
.stu-l .hed-siz{
    font-size: 1.8rem;
}
.stu-l .hiring-card button{
    width: 65%;
    height: 2rem;
    color: #ff7000;
    border: 1px solid black;
    border-radius: 5px;
    background: white;
}
.stu-2{
    width: 40%;
}
.stu-2 img{
    width: 100%;
    height: auto;
}
.fs3{
    font-size: 3rem;
}

@media only screen and (max-width: 1024px) {
    .fs3 {
        font-size: 2.5rem;
    }
}
@media only screen and (max-width: 820px) {
    .hed-siz {
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    .desc-siz {
        height: 6rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        color: grey;
    }


    .stu-l .hiring-card{
        width: 25rem;
    }
    .stu-l .hed-siz{
        font-size: 1.3rem;
    }
    .stu-l .desc-size{
        font-size: 1rem;
        margin-bottom: 1rem;
        color: grey;
    }
}

@media only screen and (max-width: 768px) {
    .hed-siz {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }


    .stu-l .hiring-card{
        width: 25rem;
    }
    .stu-l .hed-siz{
        font-size: 1.2rem;
    }
    .stu-l .desc-size{
        font-size: 0.9rem;
        margin-bottom: 1rem;
        color: grey;
    }


    .fs3 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 600px) {
    .asd{
        display: flex;
        justify-content: center;
        font-size: 1.1rem;
    }

    .hed-siz {
        font-size: 0.6rem;
        margin-bottom: 0.3rem;
    }
    .desc-siz{
        height: 2rem;
        font-size: 0.4rem;
        margin-bottom: 0.3rem;
        margin-right: 0.3rem;
        color: grey;
    }
    .hiring-card button{
        width: 100%;
        height: 0.8rem;
        color: #ff7000;
        border: 0.1px solid black;
        border-radius: 5px;
        background: white;
        font-size: 0.4rem;
    }

    .stu-l{
        width: 60%;
        display: grid;
        justify-content: space-between;
        gap: 0.5rem;
    }
    .stu-l .hiring-card{
        width: 11rem;
    }
    .stu-l .hed-siz{
        font-size: 0.7rem;
        margin-bottom: 0.2rem;
    }
    .stu-l .desc-size{
        font-size: 0.4rem;
        margin-bottom: 0rem;
        color: grey;
    }
    .stu-l .hiring-card button{
        width: 65%;
        height: 0.8rem;
        color: #ff7000;
        border: 0.5px solid black;
        border-radius: 5px;
        background: white;
    }


    .fs3 {
        font-size: 0.7rem;
    }
}